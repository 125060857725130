import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetURLSearchParameter from 'components/GetURLSearchParameter/GetURLSearchParameter';
import {
  StyledWrapper,
  StyledFormContainer,
  StyledField,
  StyledProgressIndicator,
  StyledValidationErrors,
  StyledButtonContainer,
} from './UserDashboardJobRequest.styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import GetUserID from 'components/GetUserID/GetUserID';
import GetUserAccessToken from 'components/GetUserAccessToken/GetUserAccessToken';

const UserDashboardJobRequest = () => {
  const navigate = useNavigate();
  const jobID = GetURLSearchParameter('id');

  const [startDate, setStartDate] = useState(new Date());

  const handleDateChange = (newValue: Date | null) => {
    setStartDate(newValue);
  };

  const checkEmptyFields = (fields) => {
    let isEmpty = false;

    fields.forEach((field) => {
      if (!field) {
        isEmpty = true;
      }
    });

    return isEmpty;
  };

  const handleRequestToJob = () => {
    const messageValue = document.getElementById('message').value;
    const priceValue = document.getElementById('price').value;
    const startDateValue = startDate.toISOString().split('T')[0];
    const formFields = [messageValue, priceValue];
    const progressIndicator = document.getElementById('progress-indicator');
    const emptyFieldsError = document.getElementById('empty-fields-error');

    emptyFieldsError.classList.add('hidden');
    progressIndicator.classList.remove('hidden');

    setTimeout(() => {
      progressIndicator.classList.add('hidden');

      if (checkEmptyFields(formFields)) {
        emptyFieldsError.classList.remove('hidden');
      } else {
        const requestData = {
          message: messageValue,
          price: priceValue,
          startDate: startDateValue,
          jobId: jobID,
          providerId: GetUserID(),
          clientStatus: null,
        };

        fetch(process.env.REACT_APP_PROXY + 'api/job/send_job_request', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + GetUserAccessToken(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });

        navigate('/panel-uzytkownika/szczegoly-zlecenia?id=' + jobID);
      }
    }, 750);
  };

  return (
    <StyledWrapper>
      <StyledFormContainer>
        <StyledField>
          <TextField id="message" type="text" label="Treść odpowiedzi" multiline rows={4} className="styled-input" />
        </StyledField>
        <StyledField>
          <TextField
            id="price"
            type="text"
            label="Koszt"
            className="styled-input"
            InputProps={{
              endAdornment: <InputAdornment position="end">zł</InputAdornment>,
            }}
          />
        </StyledField>
        <StyledField className="date-picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Data rozpoczęcia prac"
              inputFormat="dd.MM.yyyy"
              value={startDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
              className="styled-input"
            />
          </LocalizationProvider>
        </StyledField>
        <StyledProgressIndicator id="progress-indicator" className="hidden">
          <CircularProgress />
        </StyledProgressIndicator>
        <StyledValidationErrors>
          <div id="empty-fields-error" className="hidden">
            Wszystkie pola muszą być uzupełnione.
            <br />
            Upewnij się, że wypełnione jest każde z pól i spróbuj odpowiedzieć na ofertę ponownie.
          </div>
        </StyledValidationErrors>

        <StyledButtonContainer>
          <Button variant="contained" className="styled-button" onClick={handleRequestToJob}>
            Odpowiedz na ofertę
          </Button>
        </StyledButtonContainer>
      </StyledFormContainer>
    </StyledWrapper>
  );
};

export default UserDashboardJobRequest;

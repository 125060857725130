import React from 'react';
import PageContent from 'data/pages/Faq.json';
import GroupContainer from 'components/GroupContainer/GroupContainer';
import Title from 'components/Title/Title';
import Description from 'components/Description/Description';
import AccordionList from 'components/AccordionList/AccordionList';

const Faq = () => (
  <GroupContainer>
    <Title title={PageContent.title} />
    <Description description={PageContent.description} />
    <AccordionList items={PageContent.items} />
  </GroupContainer>
);

export default Faq;

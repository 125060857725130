import React from 'react';
import Button from '@mui/material/Button';
import { StyledContainer, StyledInfoContainer, StyledHeader, StyledText, StyledButtonsContainer } from './CookieInfo.styles';
import FindCookie from 'components/FindCookie/FindCookie';
import SetCookie from 'components/SetCookie/SetCookie';

const confirmCookies = () => {
  const cookie = document.getElementById('cookie');
  cookie.classList.toggle('hide');
  SetCookie('cookies_confirmation=true; SameSite=Lax');
};

const checkCookieConfirmation = FindCookie('cookies_confirmation=true');

const CookieInfo = () => {
  if (!checkCookieConfirmation)
    return (
      <StyledContainer id="cookie">
        <StyledInfoContainer>
          <StyledHeader>Ta strona wykorzystuje pliki cookie</StyledHeader>
          <StyledText>Na Twoim urządzeniu będą przechowywane pliki cookie z niezbędnymi danymi wymaganymi do poprawnego funkcjonowania witryny.</StyledText>
        </StyledInfoContainer>
        <StyledButtonsContainer>
          <Button variant="contained" onClick={confirmCookies}>
            Rozumiem
          </Button>
        </StyledButtonsContainer>
      </StyledContainer>
    );
};

export default CookieInfo;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/blad');
  });
};

export default PageNotFound;

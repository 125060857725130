import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledContactDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 15px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 50%;
    padding: 0 15px 0 0;
  }

  p {
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    padding: 0 0 10px 0;
  }

  a {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.darkGrey};
    text-decoration: none;
    margin: 15px 0 0 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 1.125rem;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 1.25rem;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      transition: color 0.25s;
    }

    &:hover {
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const StyledMap = styled.div`
  width: 100%;
  padding: 15px 0 0 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 50%;
    padding: 0 0 0 15px;
  }

  iframe {
    width: 100%;
    height: 100%;
    min-height: 300px;
    border: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      min-height: 350px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      min-height: 450px;
    }
  }
`;

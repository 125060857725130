import React from 'react';
import PageContent from 'data/pages/Rules.json';
import GroupContainer from 'components/GroupContainer/GroupContainer';
import Title from 'components/Title/Title';
import TextList from 'components/TextList/TextList';

const Rules = () => (
  <GroupContainer>
    <Title title={PageContent.title} />
    <TextList data={PageContent.points} />
  </GroupContainer>
);

export default Rules;

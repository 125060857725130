import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, CircularProgress } from '@mui/material';
import { StyledWrapper, StyledLoginContainer, StyledProgressIndicator, StyledError, StyledRegisterContainer } from './LoginRegister.styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const LoginRegister = () => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    const progressIndicator = document.getElementById('progress-indicator');
    const errorInfo = document.getElementById('error');
    const usernameValue = document.getElementById('username').value;
    const passwordValue = document.getElementById('password').value;
    const userData = {
      grant_type: 'password',
      username: usernameValue,
      password: passwordValue,
    };

    errorInfo.classList.add('hidden');
    progressIndicator.classList.remove('hidden');

    setTimeout(() => {
      progressIndicator.classList.add('hidden');

      fetch(process.env.REACT_APP_PROXY + 'oauth/token', {
        method: 'POST',
        headers: {
          Authorization: 'Basic c2VydmljZXdheTpCOVFVY3V6YTczS0t1REZF',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(userData),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            errorInfo.classList.remove('hidden');
          } else {
            const currentDate = new Date();
            const tokensExpirationDate = new Date(currentDate.getTime() + response.expires_in * 1000);

            window.localStorage.setItem('user', response.username);
            window.localStorage.setItem('user_id', response.userId);
            window.localStorage.setItem('user_role', response.userRole);
            window.localStorage.setItem('user_access_token', response.access_token);
            window.localStorage.setItem('user_refresh_token', response.refresh_token);
            window.localStorage.setItem('user_tokens_expiration_date', tokensExpirationDate);

            navigate('/panel-uzytkownika');
          }
        });
    }, 750);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSignIn();
    }
  };

  return (
    <Container>
      <StyledWrapper>
        <StyledLoginContainer>
          <p>Zaloguj się podając poniżej swoją nazwę użytkwonika oraz hasło.</p>
          <TextField id="username" type="text" label="Adres e-mail" autoFocus="true" onKeyDown={handleKeyDown} className="styled-input" />
          <TextField id="password" type="password" label="Hasło" onKeyDown={handleKeyDown} className="styled-input" />
          <StyledProgressIndicator id="progress-indicator" className="hidden">
            <CircularProgress />
          </StyledProgressIndicator>

          <StyledError id="error" className="hidden">
            Wprowadzono niepoprawną
            <br />
            nazwę użytkownika lub hasło
          </StyledError>
          <Button variant="contained" className="styled-button" onClick={handleSignIn}>
            Zaloguj
          </Button>
          <Link to={'/resetowanie-hasla'} className="styled-link">
            Nie pamiętasz hasła?
          </Link>
        </StyledLoginContainer>
        <StyledRegisterContainer>
          <p>Nie masz jeszcze konta?</p>
          <Button as={Link} to={'/rejestracja'} variant="contained" className="styled-button">
            Zarejestruj się
          </Button>
        </StyledRegisterContainer>
      </StyledWrapper>
    </Container>
  );
};
export default LoginRegister;

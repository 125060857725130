import styled from 'styled-components';

export const StyledWrapper = styled.div`
  grid-column: 1/2;
  grid-row: 2/3;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
`;

export const StyledNavigation = styled.nav`
  height: 100%;
  overflow-x: auto;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: auto;
    border-top: none;
    border-bottom: none;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      justify-content: center;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: block;
      height: auto;
    }

    li {
      text-align: center;
      padding: 0;

      &:first-of-type {
        a {
          @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
            border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
          }
        }
      }

      a,
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
        font-size: 1rem;
        letter-spacing: 0.03rem;
        color: ${({ theme }) => theme.colors.black};
        text-transform: uppercase;
        text-decoration: none;
        height: 100%;
        white-space: nowrap;

        @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
          display: block;
          position: relative;
          padding: 25px 0;
          border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
        }

        &::before,
        &::after {
          @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
            content: '';
            height: 1px;
            width: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background-color: ${({ theme }) => theme.colors.primary};
            transition: width 0.25s ease;
          }
        }

        &::before {
          @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
            top: -1px;
          }
        }

        &::after {
          @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
            bottom: -1px;
          }
        }

        &:hover {
          &::before,
          &::after {
            @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
              width: 100%;
            }
          }
        }
      }

      button {
        font-weight: 300;
        background-color: ${({ theme }) => theme.colors.white};
        border: none;

        @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
          width: 100%;
          margin: 70px 0 0 0;
          border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
          border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
          cursor: pointer;
        }
      }
    }
  }
`;

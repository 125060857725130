import React from 'react';
import { Container } from '@mui/material';
import FooterLinks from './FooterLinks';
import FooterIcons from './FooterIcons';
import FooterText from './FooterText';
import { StyledWrapper, StyledContainer } from './Footer.styles';

const Footer = () => (
  <StyledWrapper>
    <Container>
      <StyledContainer>
        <FooterLinks />
        <FooterIcons />
        <FooterText />
      </StyledContainer>
    </Container>
  </StyledWrapper>
);

export default Footer;

import React from 'react';
import MainBanner from 'components/MainBanner/MainBanner';
import LinkTiles from 'components/LinkTiles/LinkTiles';
import TextBanner from 'components/TextBanner/TextBanner';
import { theme } from 'assets/styles/theme';
import tileBackgroundImage1 from 'assets/images/tileBackgroundImage1.jpg';
import tileBackgroundImage2 from 'assets/images/tileBackgroundImage2.jpg';
import tileBackgroundImage3 from 'assets/images/tileBackgroundImage3.jpg';
import tileBackgroundImage4 from 'assets/images/tileBackgroundImage4.jpg';
import tileBackgroundImage5 from 'assets/images/tileBackgroundImage5.jpg';
import tileBackgroundImage6 from 'assets/images/tileBackgroundImage6.jpg';

const Home = () => (
  <>
    <MainBanner />
    <LinkTiles
      backgroundColor={theme.colors.lightGrey}
      tileText1="Sprawdzeni specjaliści"
      tileBackgroundImage1={tileBackgroundImage1}
      tileText2="Najwyższa jakość usług"
      tileBackgroundImage2={tileBackgroundImage2}
      tileText3="Samemu decydujesz o cenie"
      tileBackgroundImage3={tileBackgroundImage3}
      tileTextBackgroundColor={theme.colors.white}
    />
    <TextBanner textContent="Serviceway to definicja najwyższej jakośći usług. W naszym serwisie nie musisz szukać specjalisty. To specjaliści czekają na Ciebie. Wystarczy, że utworzysz konto użytkownika i dodasz swoje zlecenie w serwisie. Jeśli specjalista będzie chciał nawiązać z Tobą współpracę, otrzymasz od niego wycenę swojego zlecenia. To od Ciebie zależy, czy zgadzasz się na wycenę, czy chcesz poczekać na innego specjalistę. To takie proste!" />
    <TextBanner
      textAlign="textOnRight"
      backgroundColor={theme.colors.lightGrey}
      textContent="Jeśli z Twoim zleceniem coś poszło nie tak, Serviceway czeka na Twoje zgłoszenie. Na podstawie informacji ze zlecenia, wykonanych zdjęć oraz zeznań obu stron, Serviceway pomoże Ci rozwiązać spór. W przypadku rażących odstępstw zwrócimy Ci pieniądze, a specjalista otrzyma ostrzeżenie, lub zostanie wykluczony z serwisu. Serviceway łączy tylko rzetelnych i uczciwych specjalistów - nie ma tu miejsca na oszustwa. Twoje zlecenie jest z nami bezpieczne."
    />
    <LinkTiles
      tileText1="Gwarancja zwrotu pieniędzy"
      tileBackgroundImage1={tileBackgroundImage4}
      tileText2="Wsparcie ze strony Serviceway"
      tileBackgroundImage2={tileBackgroundImage5}
      tileText3="Pomoc w rozwiązywaniu sporów"
      tileBackgroundImage3={tileBackgroundImage6}
      tileTextBackgroundColor={theme.colors.lightGrey}
    />
  </>
);

export default Home;

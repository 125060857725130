import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';
import { StyledWrapper, StyledContainer, LinkTilesItem, LinkTileImage, LinkTileText } from './LinkTiles.styles';

const LinkTiles = ({
  backgroundColor = 'transparent',
  tileText1,
  tileBackgroundImage1,
  tileText2,
  tileBackgroundImage2,
  tileText3,
  tileBackgroundImage3,
  tileTextBackgroundColor = 'transparent',
}) => (
  <StyledWrapper style={{ backgroundColor: backgroundColor }}>
    <Container>
      <StyledContainer>
        <LinkTilesItem href="/panel-uzytkownika">
          <LinkTileImage style={{ backgroundImage: 'url(' + tileBackgroundImage1 + ')' }}></LinkTileImage>
          <LinkTileText style={{ backgroundColor: tileTextBackgroundColor }}>{tileText1}</LinkTileText>
        </LinkTilesItem>
        <LinkTilesItem href="/panel-uzytkownika">
          <LinkTileImage style={{ backgroundImage: 'url(' + tileBackgroundImage2 + ')' }}></LinkTileImage>
          <LinkTileText style={{ backgroundColor: tileTextBackgroundColor }}>{tileText2}</LinkTileText>
        </LinkTilesItem>
        <LinkTilesItem href="/panel-uzytkownika">
          <LinkTileImage style={{ backgroundImage: 'url(' + tileBackgroundImage3 + ')' }}></LinkTileImage>
          <LinkTileText style={{ backgroundColor: tileTextBackgroundColor }}>{tileText3}</LinkTileText>
        </LinkTilesItem>
      </StyledContainer>
    </Container>
  </StyledWrapper>
);

LinkTiles.propTypes = {
  backgroundColor: PropTypes.string,
  tileText1: PropTypes.string.isRequired,
  tileBackgroundImage1: PropTypes.string.isRequired,
  tileText2: PropTypes.string.isRequired,
  tileBackgroundImage2: PropTypes.string.isRequired,
  tileText3: PropTypes.string.isRequired,
  tileBackgroundImage3: PropTypes.string.isRequired,
};

export default LinkTiles;

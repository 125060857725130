import React from 'react';
import PageContent from 'data/pages/Rodo.json';
import GroupContainer from 'components/GroupContainer/GroupContainer';
import Title from 'components/Title/Title';
import Description from 'components/Description/Description';
import TextList from 'components/TextList/TextList';

const Rodo = () => (
  <GroupContainer>
    <Title title={PageContent.title} />
    <Description description={PageContent.description} />
    <TextList data={PageContent.points} />
  </GroupContainer>
);

export default Rodo;

import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import { StyledWrapper, StyledContainer, MenuButton, MenuContent, LogoContainer, UserButton } from './Navigation.styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoSmall from 'assets/images/logo-small.svg';
import LogoBig from 'assets/images/logo-big.svg';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import isUserLoggedIn from 'components/IsUserLoggedIn/IsUserLoggedIn';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const toggleMenu = () => {
  const menuContent = document.getElementById('menuElements');
  menuContent.classList.toggle('show');
};

const Navigation = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_PROXY + 'api/category/')
      .then((response) => response.json())
      .then((result) => {
        setCategories(result);
      });
  }, []);

  return (
    <StyledWrapper>
      <Container>
        <StyledContainer>
          <MenuButton>
            <MenuIcon onClick={toggleMenu} />
          </MenuButton>
          <MenuContent id="menuElements">
            <CloseIcon onClick={toggleMenu} />
            <div>
              <span>kategorie usług</span>
              <ul>
                {categories.map((element) => (
                  <li key={element.name}>
                    <Link to="/panel-uzytkownika">{element.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <Link to="/kontakt">kontakt</Link>
            </div>
            <div>
              <Link to="/faq">faq</Link>
            </div>
          </MenuContent>
          <LogoContainer to="/">
            <img id="logo-small" src={LogoSmall} alt="logo strony" />
            <img id="logo-big" src={LogoBig} alt="logo strony" />
          </LogoContainer>
          <UserButton>
            {isUserLoggedIn() ? (
              <Button as={Link} to="/panel-uzytkownika" variant="contained" className="styled-button">
                <AccountCircleIcon />
                <span>panel użytkownika</span>
              </Button>
            ) : (
              <Button as={Link} to="/logowanie" variant="contained" className="styled-button">
                <AccountCircleIcon />
                <span>zaloguj</span>
              </Button>
            )}
          </UserButton>
        </StyledContainer>
      </Container>
    </StyledWrapper>
  );
};

export default Navigation;

import styled from 'styled-components';
import { theme } from 'assets/styles/theme';

export const StyledWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }
`;

export const StyledContainer = styled.div`
  text-align: center;
  position: relative;
`;

export const Title = styled.h1`
  color: white;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 2.75rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 3rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 4rem;
  }
`;

export const Text = styled.p`
  letter-spacing: 0.03rem;
  font-weight: 100;
  color: white;
  text-transform: uppercase;
  padding: 50px 0;
`;

export const Button = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    transition: color 0.2s linear;
  }

  &:hover {
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      color: ${theme.colors.primary};
    }
  }

  svg {
    width: 60px;
    height: 60px;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      width: 80px;
      height: 80px;
    }
  }
`;

import React from 'react';
import { StyledLinks } from './FooterLinks.styles';
import { NavLink } from 'react-router-dom';

const FooterLinks = () => (
  <StyledLinks>
    <NavLink to="/kontakt">kontakt</NavLink>
    <NavLink to="/faq">faq</NavLink>
    <NavLink to="/regulamin">regulamin</NavLink>
    <NavLink to="/polityka-prywatnosci">polityka prywatności</NavLink>
    <NavLink to="/rodo">rodo</NavLink>
  </StyledLinks>
);

export default FooterLinks;

import React from 'react';
import { Container } from '@mui/material';
import ElementContainer from 'components/ElementContainer/ElementContainer';
import { StyledWrapper } from './TextList.styles';
import PropTypes from 'prop-types';

const TextList = ({ data }) => (
  <Container>
    <ElementContainer>
      <StyledWrapper>
        <ol>
          {data.map((element, index) => (
            <>
              <li key={index}>
                {element.pointText}
                {element.subpoints && (
                  <ul>
                    {element.subpoints.map((element, index) => (
                      <li>
                        {element.subpointText}
                        {element.subpoints && (
                          <ul>
                            {element.subpoints.map((element, index) => (
                              <li>{element.subpointText}</li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </>
          ))}
        </ol>
      </StyledWrapper>
    </ElementContainer>
  </Container>
);

TextList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default TextList;

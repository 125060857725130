import React from 'react';
import PageContent from 'data/pages/Contact.json';
import GroupContainer from 'components/GroupContainer/GroupContainer';
import Title from 'components/Title/Title';
import Description from 'components/Description/Description';
import ContactInfo from 'components/ContactInfo/ContactInfo';

const Contact = () => (
  <GroupContainer>
    <Title title={PageContent.title} />
    <Description description={PageContent.description} />
    <ContactInfo />
  </GroupContainer>
);

export default Contact;

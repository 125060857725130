import styled from 'styled-components';

export const StyledWrapper = styled.div`
  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    background-color: ${({ theme }) => theme.colors.primary};
    box-shadow: none;
    text-decoration: none;

    &:hover {
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        background-color: ${({ theme }) => theme.colors.grey};
        box-shadow: none;
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';
import { StyledWrapper, StyledContainer } from './TextBanner.styles';

const TextBanner = ({ backgroundColor = 'transparent', textAlign = 'textOnLeft', textContent }) => (
  // TODO: use condition in style, add backgroundImage option
  <StyledWrapper style={{ backgroundColor: backgroundColor }}>
    <Container>
      <StyledContainer className={textAlign}>
        <p>{textContent}</p>
      </StyledContainer>
    </Container>
  </StyledWrapper>
);

TextBanner.propTypes = {
  backgroundColor: PropTypes.string,
  textAlign: PropTypes.string,
  textContent: PropTypes.string.isRequired,
};

export default TextBanner;

import React from 'react';
import IsUserLoggedIn from 'components/IsUserLoggedIn/IsUserLoggedIn';
import { Navigate } from 'react-router-dom';
import { StyledWebsiteContent } from 'templates/Website/WebsiteTemplate.styles';
import Navigation from 'components/Navigation/Navigation';
import Footer from 'components/Footer/Footer';

const WebsiteTemplate = ({ children }) => {
  const currentPagePathName = window.location.pathname;

  return IsUserLoggedIn() && currentPagePathName === '/logowanie' ? (
    <Navigate to="/panel-uzytkownika" />
  ) : (
    <StyledWebsiteContent>
      <Navigation />
      {children}
      <Footer />
    </StyledWebsiteContent>
  );
};

export default WebsiteTemplate;

import styled from 'styled-components';

export const StyledUserDashboardContent = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 70px 70px calc(100% - 140px);

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 320px calc(100% - 320px);
    grid-template-rows: 80px calc(100% - 80px);
  }
`;

export const StyledSelectedElementContent = styled.div`
  grid-column: 1/2;
  grid-row: 3/4;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  overflow-y: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-column: 2/3;
    grid-row: 2/3;
    padding: 60px;
  }
`;

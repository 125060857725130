import styled from 'styled-components';

export const StyledWrapper = styled.div`
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 560px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 60px;
  }
`;

export const StyledFieldsContainer = styled.div`
  padding: 0 0 15px 0;

  &.noBottomPadding {
    padding: 0;
  }
`;

export const StyledField = styled.div`
  padding: 0 0 30px 0;

  .styled-input {
    max-width: none;
    width: 100%;
  }
`;

export const StyledProgressIndicator = styled.div`
  text-align: center;

  &.hidden {
    display: none;
  }
`;

export const StyledValidationErrors = styled.div`
  text-align: center;

  > div {
    color: ${({ theme }) => theme.colors.error};

    &.hidden {
      display: none;
    }
  }
`;

export const StyledButtonContainer = styled.div`
  text-align: center;
  padding: 30px 0 0 0;
`;

import React from 'react';
import { Container } from '@mui/material';
import ElementContainer from 'components/ElementContainer/ElementContainer';
import { StyledGroup, StyledAccordion } from './AccordionList.styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import PropTypes from 'prop-types';

const AccordionList = ({ items }) => (
  <ElementContainer>
    {items.map((group, index) => (
      <StyledGroup key={index}>
        <Container>
          {group.group.map((element, index) => {
            return (
              <StyledAccordion key={index}>
                <Accordion className="StyledAccordion">
                  <AccordionSummary expandIcon={<ExpandMoreIcon className="StyledExpandMoreIcon" />} className="StyledAccordionSummary">
                    {element.question}
                  </AccordionSummary>
                  <AccordionDetails className="StyledAccordionDetails">{element.answer}</AccordionDetails>
                </Accordion>
              </StyledAccordion>
            );
          })}
        </Container>
      </StyledGroup>
    ))}
  </ElementContainer>
);

AccordionList.propTypes = {
  items: PropTypes.array.isRequired,
};

export default AccordionList;

import React from 'react';
import { Container } from '@mui/material';
import ElementContainer from 'components/ElementContainer/ElementContainer';
import PropTypes from 'prop-types';

const Description = ({ description }) => (
  <Container>
    <ElementContainer>
      <p>{description}</p>
    </ElementContainer>
  </Container>
);

Description.propTypes = {
  description: PropTypes.string.isRequired,
};

export default Description;

import styled from 'styled-components';

export const StyledText = styled.div`
  text-align: center;
  padding: 20px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: 1/3;
  }

  span {
    color: ${({ theme }) => theme.colors.black};
    font-size: 0.875rem;
    text-transform: uppercase;
  }
`;

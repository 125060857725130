import React from 'react';
import { StyledWrapper, StyledText, StyledField, StyledProgressIndicator, StyledError, StyledButton } from './UserDashboardHelpForm.styles';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';

const handleMessageSend = () => {
  const messageFieldValue = document.getElementById('message-field').value;
  const emptyFieldError = document.getElementById('empty-field-error');
  const progressIndicator = document.getElementById('progress-indicator');

  emptyFieldError.classList.add('hidden');
  progressIndicator.classList.remove('hidden');

  setTimeout(() => {
    progressIndicator.classList.add('hidden');

    if (!messageFieldValue) {
      emptyFieldError.classList.remove('hidden');
    }
  }, 750);
};

const UserDashboardHelpForm = () => (
  <StyledWrapper>
    <StyledText>
      <span>
        Jeśli potrzebujesz pomocy możesz napisać do nas pod adres e-mail <a href="mailto:pomoc@serviceway.pl">pomoc@serviceway.pl</a> lub skorzystać z
        poniższego formularza kontaktowego.
      </span>
    </StyledText>
    <StyledField>
      <TextField id="message-field" type="text" label="Treść wiadomości" multiline rows={6} className="styled-input" />
    </StyledField>
    <StyledProgressIndicator id="progress-indicator" className="hidden">
      <CircularProgress />
    </StyledProgressIndicator>
    <StyledError id="empty-field-error" className="hidden">
      Pole z treścią wiadomości nie może być puste.
      <br />
      Upewnij się, że jest ono wypełnione i wyślij wiadomość ponownie.
    </StyledError>
    <StyledButton>
      <Button variant="contained" className="styled-button" onClick={handleMessageSend}>
        Wyślij
      </Button>
    </StyledButton>
  </StyledWrapper>
);

export default UserDashboardHelpForm;

import styled from 'styled-components';

export const StyledWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 50%;
    padding: 45px 15px 45px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 60px 15px 60px 0;
  }

  > p {
    text-align: center;
    padding: 0 0 60px 0;
    max-width: 400px;
  }

  .styled-input {
    padding: 0 0 30px 0;
  }

  .styled-button {
    margin: 15px 0 0 0;
  }

  .styled-link {
    margin: 60px 0 0 0;
  }
`;

export const StyledProgressIndicator = styled.div`
  margin: 0 0 15px 0;

  > span {
    color: ${({ theme }) => theme.colors.primary};
  }

  &.hidden {
    display: none;
  }
`;

export const StyledError = styled.span`
  text-align: center;
  margin: 0 0 15px 0;
  color: ${({ theme }) => theme.colors.error};

  &.hidden {
    display: none;
  }
`;

export const StyledRegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 50%;
    padding: 45px 0 45px 15px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 60px 0 60px 15px;
  }

  p {
    text-align: center;
    padding: 0 0 45px 0;
  }
`;

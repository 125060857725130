import React from 'react';
import { StyledIcons } from './FooterIcons.styles';
import { SocialIcon } from 'react-social-icons';

const FooterIcons = () => (
  <StyledIcons>
    <SocialIcon bgColor="#000000" fgColor="#ffffff" url="https://www.facebook.com/" target="_blank" />
    <SocialIcon bgColor="#000000" fgColor="#ffffff" url="https://www.twitter.com/" target="_blank" />
    <SocialIcon bgColor="#000000" fgColor="#ffffff" url="https://www.instagram.com/" target="_blank" />
  </StyledIcons>
);

export default FooterIcons;

import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// region User type selection
export const StyledUserTypeSelection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiFormControl-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .MuiFormLabel-root {
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.black};
      font-weight: 300;
      padding: 0 0 10px 0;

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 1.125rem;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 1.25rem;
      }
    }

    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .MuiFormControlLabel-root {
        margin: 0;

        &:first-of-type {
          margin: 0 20px 0 0;
        }
      }
    }
  }
`;
// endregion

// region User data
export const StyledUserData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }
`;
// endregion

// region Rules consent
export const StyledRulesConsent = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
`;
// endregion

// region Progress indicator
export const StyledProgressIndicator = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 15px 0;

  &.hidden {
    display: none;
  }

  > span {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
// endregion

// region Validation errors
export const StyledValidationErrors = styled.div`
  div {
    text-align: center;
    padding: 0 0 15px 0;
    color: ${({ theme }) => theme.colors.error};

    &.hidden {
      display: none;
    }
  }
`;
// endregion

// region Registration button
export const StyledRegistrationButton = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
`;
// endregion

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 50%;
  }

  &:nth-of-type(odd) {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      align-items: flex-end;
      padding: 0 45px 0 0;
    }
  }

  &:nth-of-type(even) {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      align-items: flex-start;
      padding: 0 0 0 45px;
    }
  }
`;

export const StyledFieldsGroup = styled.div`
  padding: 15px 0;
`;

export const StyledInput = styled.div`
  padding: 15px 0;
`;

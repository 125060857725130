import styled from 'styled-components';

export const StyledGroup = styled.div`
  padding: 20px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 30px 0;
  }

  &:nth-of-type(odd) {
    .StyledAccordion {
      background-color: ${({ theme }) => theme.colors.lightGrey};
    }
  }

  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

export const StyledAccordion = styled.div`
  padding: 20px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 30px 0;
  }

  .StyledAccordion {
    box-shadow: none;

    .StyledAccordionSummary {
      padding: 20px;
      min-height: 0;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 30px;
      }

      .MuiAccordionSummary-content {
        font-size: 0.875rem;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.03rem;
        padding: 0 20px 0 0;
        margin: 0;

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 1rem;
          padding: 0 30px 0 0;
        }
      }
    }

    .StyledExpandMoreIcon {
      font-size: 1.5rem;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 2rem;
      }
    }

    .StyledAccordionDetails {
      font-size: 0.875rem;
      padding: 0 64px 20px 20px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 1rem;
        padding: 0 92px 30px 30px;
      }
    }
  }
`;

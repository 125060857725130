import React from 'react';
import IsUserLoggedIn from 'components/IsUserLoggedIn/IsUserLoggedIn';
import { StyledUserDashboardContent, StyledSelectedElementContent } from './UserDashboardTemplate.styles';
import UserDashboardTopBar from 'components/UserDashboard/UserDashboardTopBar/UserDashboardTopBar';
import UserDashboardMenu from 'components/UserDashboard/UserDashboardMenu/UserDashboardMenu';
import { Navigate } from 'react-router-dom';

const UserDashboardTemplate = ({ pageTitle, children }) =>
  IsUserLoggedIn() ? (
    <StyledUserDashboardContent>
      <UserDashboardTopBar pageTitle={pageTitle} />
      <UserDashboardMenu />
      <StyledSelectedElementContent>{children}</StyledSelectedElementContent>
    </StyledUserDashboardContent>
  ) : (
    <Navigate to="/logowanie" />
  );

export default UserDashboardTemplate;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GetUserName from 'components/GetUserName/GetUserName';
// import GetUserID from 'components/GetUserID/GetUserID';
import GetUserAccessToken from 'components/GetUserAccessToken/GetUserAccessToken';
import ClearLocalStorage from 'components/ClearLocalStorage/ClearLocalStorage';
import {
  StyledWrapper,
  StyledSection,
  StyledHeader,
  StyledField,
  StyledProgressIndicator,
  StyledValidationInfo,
  StyledButtons,
} from './UserDashboardUserSettings.styles';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const UserDashboardUserSettings = () => {
  const navigate = useNavigate();
  const userName = GetUserName();
  const UserAccessToken = GetUserAccessToken();
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');

  const updateUserData = () => {};

  const changePassword = () => {
    const passwordChangeProgressIndicator = document.getElementById('password-change-progress-indicator');
    const emptyPasswordFieldError = document.getElementById('empty-password-field-error');
    const incorrectCurrentPasswordError = document.getElementById('incorrect-current-password-error');
    const passwordChangeSuccess = document.getElementById('password-change-success');

    passwordChangeProgressIndicator.classList.remove('hidden');
    emptyPasswordFieldError.classList.add('hidden');
    incorrectCurrentPasswordError.classList.add('hidden');
    passwordChangeSuccess.classList.add('hidden');

    const currentPasswordValue = document.getElementById('current-password').value;
    const newPasswordValue = document.getElementById('new-password').value;

    setTimeout(() => {
      passwordChangeProgressIndicator.classList.add('hidden');

      if (!currentPasswordValue || !newPasswordValue) {
        emptyPasswordFieldError.classList.remove('hidden');
      } else {
        fetch(
          process.env.REACT_APP_PROXY +
            'api/user/change_password/' +
            userName +
            '?oldPassword=' +
            currentPasswordValue +
            '&newPassword=' +
            newPasswordValue +
            '/',
          {
            method: 'PUT',
            headers: {
              Authorization: 'Bearer ' + UserAccessToken,
              'Content-Type': 'application/json',
            },
          }
        ).then((response) => {
          if (response.ok) {
            passwordChangeSuccess.classList.remove('hidden');
          } else {
            incorrectCurrentPasswordError.classList.remove('hidden');
          }
        });
      }
    }, 750);
  };

  const deleteAccount = () => {
    fetch(process.env.REACT_APP_PROXY + 'api/user/delete/' + userName + '/', {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + UserAccessToken,
      },
    });

    ClearLocalStorage();

    navigate('/');
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_PROXY + 'api/user/' + userName + '/', {
      headers: {
        Authorization: 'Bearer ' + UserAccessToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setUserFirstName(result.firstName);
        setUserLastName(result.lastName);
        setUserPhoneNumber(result.phone);
      });
  });

  return (
    <StyledWrapper>
      <StyledSection>
        <StyledHeader>dane użytkownika</StyledHeader>
        <StyledField>
          <TextField id="username" type="text" disabled label="Nazwa użytkownika" value={userName} className="styled-input disabled" />
        </StyledField>
        <StyledField>
          <TextField id="first-name" type="text" label="Imię" value={userFirstName} className="styled-input" />
        </StyledField>
        <StyledField>
          <TextField id="last-name" type="text" label="Nazwisko" value={userLastName} className="styled-input" />
        </StyledField>
        <StyledField>
          <TextField id="phone-number" type="text" label="Numer telefonu" value={userPhoneNumber} className="styled-input" />
        </StyledField>
        <Button variant="contained" className="styled-button" onClick={updateUserData}>
          Zapisz
        </Button>
      </StyledSection>
      <StyledSection>
        <StyledHeader>zmiana hasła</StyledHeader>
        <StyledField>
          <TextField id="current-password" type="password" label="Obecne hasło" className="styled-input" />
        </StyledField>
        <StyledField>
          <TextField id="new-password" type="password" label="Nowe hasło" className="styled-input" />
        </StyledField>
        <StyledProgressIndicator id="password-change-progress-indicator" className="hidden">
          <CircularProgress />
        </StyledProgressIndicator>
        <StyledValidationInfo>
          <div id="empty-password-field-error" className="hidden">
            Oba pola muszą być wypełnione.
          </div>
          <div id="incorrect-current-password-error" className="hidden">
            Wprowadzono niepoprawne dotychczasowe hasło.
          </div>
          <div id="password-change-success" className="hidden">
            Hasło zostało zmienione.
          </div>
        </StyledValidationInfo>
        <Button variant="contained" className="styled-button" onClick={changePassword}>
          Zmień hasło
        </Button>
      </StyledSection>
      <StyledButtons>
        <Button variant="contained" className="styled-button" onClick={deleteAccount}>
          Usuń konto
        </Button>
      </StyledButtons>
    </StyledWrapper>
  );
};

export default UserDashboardUserSettings;

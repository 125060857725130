import styled from 'styled-components';

export const StyledWrapper = styled.div`
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 560px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 60px;
  }
`;

export const StyledSection = styled.div`
  padding: 0 0 60px 0;
`;

export const StyledHeader = styled.h2`
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 0.03rem;
  margin: 0;
  padding: 0 0 30px 0;
`;

export const StyledField = styled.div`
  padding: 0 0 30px 0;

  .styled-input {
    max-width: none;
    width: 100%;
  }
`;

export const StyledProgressIndicator = styled.div`
  margin: 0 0 30px 0;

  &.hidden {
    display: none;
  }

  &#password-change-progress-indicator {
    padding: 0 0 0 42px;
  }

  > span {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledValidationInfo = styled.div`
  .hidden {
    display: none;
  }

  > div {
    padding: 0 0 30px 0;

    &#empty-password-field-error,
    &#incorrect-current-password-error {
      color: ${({ theme }) => theme.colors.error};
    }
  }
`;

export const StyledButtons = styled.div`
  padding: 30px 0 0 0;
`;

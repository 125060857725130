import styled from 'styled-components';

export const StyledWrapper = styled.div`
  ol {
    font-size: 1rem;
    font-weight: 400;
    padding: 0 0 0 25px;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 1.125rem;
      padding: 0 0 0 29px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 1.25rem;
      padding: 0 0 0 31px;
    }

    li {
      margin: 5px 0 0 0;
    }

    > li {
      padding: 0 0 15px 0;

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding: 0 0 20px 0;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 0 0 25px 0;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        padding: 0 0 30px 0;
      }

      > ul {
        font-size: 0.875rem;
        font-weight: 300;
        list-style-type: disc;
        padding: 0 0 0 15px;

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          font-size: 1rem;
          padding: 0 0 0 17px;
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 1.125rem;
          padding: 0 0 0 20px;
        }

        > li {
          > ul {
            list-style-type: circle;
            padding: 0 0 0 15px;

            @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
              padding: 0 0 0 17px;
            }

            @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
              padding: 0 0 0 20px;
            }
          }
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: 1/2;
    justify-content: flex-start;
  }

  a {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.black};
    text-transform: uppercase;
    text-decoration: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      transition: color 0.2s linear;
    }

    &:not(:last-of-type) {
      margin: 0 0 15px 0;

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin: 0;
      }

      &:after {
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          content: '•';
          color: ${({ theme }) => theme.colors.black};
          padding: 0 15px;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
          padding: 0 16px;
        }
      }
    }

    &:hover {
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

import React from 'react';
import { StyledText } from './FooterText.styles';

const FooterText = () => (
  <StyledText>
    <span>&#169; 2022 serviceway</span>
  </StyledText>
);

export default FooterText;

import styled from 'styled-components';

export const StyledContainer = styled.div`
  grid-column: 1/2;
  grid-row: 1/2;
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-column: 1/3;
    grid-row: 1/2;
    justify-content: center;
  }
`;

export const StyledLogo = styled.div`
  height: 50px;
  width: 50px;
  margin: 0 15px 0 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 195px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 100%;
    width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      height: 55%;
      max-width: 100%;
    }

    img {
      height: 100%;

      &#logo-small {
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          display: none;
        }
      }

      &#logo-big {
        display: none;

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          display: block;
        }
      }
    }
  }
`;

export const StyledTitle = styled.h1`
  font-size: 1rem;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: calc(100% - 50px);
  margin: 0 30px 0 15px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.125rem;
    width: calc(100% - 195px);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.25rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: calc(100% - 320px);
    margin: 0;
  }

  .text-separator {
    padding: 0 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding: 0 15px;
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  StyledWrapper,
  StyledJobDetailsContainer,
  StyledJobDetail,
  StyledJobDetailTitle,
  StyledJobDetailLabel,
  StyledJobDetailText,
  StyledButtonsContainer,
  StyledJobRequestsContainer,
  StyledJobRequestsListHeader,
  StyledJobRequestsList,
  StyledJobRequest,
  StyledJobRequestMessage,
  StyledJobRequestPrice,
  StyledJobRequestDate,
  StyledJobRequestButtons,
  StyledJobConfirmation,
  StyledRate,
  StyledRateText,
  StyledRateElement,
  StyledJobRequestLabel,
  StyledJobRequestText,
  StyledJobRequestStatus,
} from './UserDashboardJob.styles';
import { format } from 'date-fns';
import GetURLSearchParameter from 'components/GetURLSearchParameter/GetURLSearchParameter';
import GetUserType from 'components/GetUserType/GetUserType';
import GetUserAccessToken from 'components/GetUserAccessToken/GetUserAccessToken';
import Button from '@mui/material/Button';
import { Link, generatePath } from 'react-router-dom';
import Rating from '@mui/material/Rating';

const RefreshPage = () => {
  window.location.reload(false);
};

const UserDashboardJob = () => {
  const userType = GetUserType();
  const [jobID, setJobID] = useState('');
  const [jobName, setJobName] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [jobAddress, setJobAddress] = useState({});
  const [jobPreferredDate, setJobPreferredDate] = useState('');
  const [jobStartDate, setJobStartDate] = useState('');
  const [jobStatus, setJobStatus] = useState('');
  const [jobRequestsList, setJobRequestsList] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_PROXY + 'api/job/' + GetURLSearchParameter('id') + '/', {
      headers: {
        Authorization: 'Bearer ' + GetUserAccessToken(),
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setJobID(result._id);
        setJobName(result.subcategory);
        setJobDescription(result.details);
        setJobAddress(result.address);
        setJobPreferredDate(result.prefferDate);
        setJobStartDate(result.startDate);
        setJobStatus(result.status);
        setJobRequestsList(result.jobRequestList);
      });
  }, []);

  const navigate = useNavigate();

  const cancelJob = () => {
    fetch(process.env.REACT_APP_PROXY + 'api/job/cancel_job/' + GetURLSearchParameter('id') + '/', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + GetUserAccessToken(),
        'Content-Type': 'application/json',
      },
    });

    navigate('/panel-uzytkownika');
  };

  const acceptOffer = (id) => {
    fetch(process.env.REACT_APP_PROXY + 'api/job/confirm_provider/' + id + '/', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + GetUserAccessToken(),
        'Content-Type': 'application/json',
      },
    }).then((response) => RefreshPage());
  };

  const rejectOffer = (id) => {
    fetch(process.env.REACT_APP_PROXY + 'api/job/reject_provider/' + id + '/', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + GetUserAccessToken(),
        'Content-Type': 'application/json',
      },
    }).then((response) => RefreshPage());
  };

  const payForTheJob = () => {
    fetch(process.env.REACT_APP_PROXY + 'api/job/create_and_send_payment/' + jobID + '/', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + GetUserAccessToken(),
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.text())
      .then((result) => window.open(result, '_self'));
  };

  const [clientRate, setClientRate] = useState(5);

  const endJob = () => {
    fetch(process.env.REACT_APP_PROXY + 'api/job/confirm_job/' + jobID + '?clientRating=' + clientRate, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + GetUserAccessToken(),
        'Content-Type': 'application/json',
      },
    });

    navigate('/panel-uzytkownika');
  };

  const [providerRate, setProviderRate] = useState(5);

  const confirmJob = () => {
    fetch(process.env.REACT_APP_PROXY + 'api/job/rate_provider/' + jobID + '?providerRate=' + providerRate, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + GetUserAccessToken(),
        'Content-Type': 'application/json',
      },
    });

    navigate('/panel-uzytkownika');
  };

  return (
    <StyledWrapper>
      <StyledJobDetailsContainer>
        <StyledJobDetail>
          <StyledJobDetailTitle>{jobName}</StyledJobDetailTitle>
        </StyledJobDetail>
        <StyledJobDetail>
          <StyledJobDetailLabel>Opis zlecenia: </StyledJobDetailLabel>
          <StyledJobDetailText>{jobDescription}</StyledJobDetailText>
        </StyledJobDetail>
        <StyledJobDetail>
          <StyledJobDetailLabel>Adres: </StyledJobDetailLabel>
          <StyledJobDetailText>
            {jobAddress.addressLine1}
            {jobAddress.addressLine2 && (
              <>
                <br />
                {jobAddress.addressLine2}
              </>
            )}
            ,
            <br />
            {jobAddress.zipCode} {jobAddress.city}
          </StyledJobDetailText>
        </StyledJobDetail>
        <StyledJobDetail>
          <StyledJobDetailLabel>Preferowany termin realizacji: </StyledJobDetailLabel>
          <StyledJobDetailText>{jobPreferredDate}</StyledJobDetailText>
        </StyledJobDetail>
        <StyledJobDetail>
          <StyledJobDetailLabel>Data zgłoszenia: </StyledJobDetailLabel>
          <StyledJobDetailText>{jobStartDate && format(new Date(jobStartDate), 'dd.MM.yyyy')}</StyledJobDetailText>
        </StyledJobDetail>
        <StyledJobDetail>
          <StyledJobDetailLabel>Status zlecenia: </StyledJobDetailLabel>
          <StyledJobDetailText>
            {jobStatus === 'WAITING_FOR_PROVIDER' && 'oczekuje na ofertę'}
            {jobStatus === 'WAITING_FOR_APPROVAL' && 'oczekuje na akceptację oferty'}
            {jobStatus === 'WAITING_FOR_PAYMENT' && 'oczekuje na opłacenie'}
            {jobStatus === 'JOB_IN_PROGRESS' && 'oczekuje na zakończenie zlecenia'}
            {jobStatus === 'JOB_DONE_BY_PROVIDER' && 'oczekuje na potwierdzenie zakończenie zlecenia'}
            {jobStatus === 'JOB_DONE' && 'zakończone'}
            {jobStatus === 'JOB_CANCELED' && 'anulowane'}
          </StyledJobDetailText>
        </StyledJobDetail>
        <StyledButtonsContainer>
          {(jobStatus === 'WAITING_FOR_PROVIDER' || jobStatus === 'WAITING_FOR_APPROVAL') && userType === 'CLIENT' && (
            <Button variant="contained" className="styled-button" onClick={cancelJob}>
              Anuluj zlecenie
            </Button>
          )}
          {jobStatus === 'WAITING_FOR_PAYMENT' && userType === 'CLIENT' && (
            <Button variant="contained" className="styled-button" onClick={payForTheJob}>
              opłać zlecenie
            </Button>
          )}
          {jobStatus === 'JOB_DONE_BY_PROVIDER' && userType === 'CLIENT' && (
            <StyledJobConfirmation>
              <StyledRate>
                <StyledRateText>Oceń zleceniobiorcę:</StyledRateText>
                <StyledRateElement>
                  <Rating
                    name="provider-rating"
                    value={providerRate}
                    onChange={(event, newRate) => {
                      setProviderRate(newRate);
                    }}
                  />
                </StyledRateElement>
                <StyledRateText>i</StyledRateText>
              </StyledRate>

              <Button variant="contained" className="styled-button" onClick={confirmJob}>
                Zatwierdź ukończenie zlecenie
              </Button>
            </StyledJobConfirmation>
          )}

          {(jobStatus === 'WAITING_FOR_PROVIDER' || jobStatus === 'WAITING_FOR_APPROVAL') && userType === 'PROVIDER' && (
            <Button
              as={Link}
              to={generatePath('/panel-uzytkownika/odpowiedz-na-oferte?id=:id', {
                id: jobID,
              })}
              variant="contained"
              className="styled-button"
            >
              Odpowiedz na ofertę
            </Button>
          )}
          {jobStatus === 'JOB_IN_PROGRESS' && userType === 'PROVIDER' && (
            <StyledJobConfirmation>
              <StyledRate>
                <StyledRateText>Oceń zleceniodawcę:</StyledRateText>
                <StyledRateElement>
                  <Rating
                    name="client-rating"
                    value={clientRate}
                    onChange={(event, newRate) => {
                      setClientRate(newRate);
                    }}
                  />
                </StyledRateElement>
                <StyledRateText>i</StyledRateText>
              </StyledRate>

              <Button variant="contained" className="styled-button" onClick={endJob}>
                Zakończ zlecenie
              </Button>
            </StyledJobConfirmation>
          )}
        </StyledButtonsContainer>
      </StyledJobDetailsContainer>
      {userType === 'CLIENT' && (
        <StyledJobRequestsContainer>
          <StyledJobRequestsListHeader>{userType === 'CLIENT' ? 'otrzymane oferty' : 'złożone przez Ciebie oferty'}</StyledJobRequestsListHeader>
          <StyledJobRequestsList>
            {jobRequestsList[0] === null || jobRequestsList.length === 0 ? (
              <span>brak</span>
            ) : (
              jobRequestsList.map(
                (element, index) =>
                  element.clientStatus !== 'Oferta odrzucona' && (
                    <StyledJobRequest key={index}>
                      <StyledJobRequestMessage>
                        <StyledJobRequestLabel>treśc oferty:</StyledJobRequestLabel>
                        <StyledJobRequestText>{element.message}</StyledJobRequestText>
                      </StyledJobRequestMessage>
                      <StyledJobRequestPrice>
                        <StyledJobRequestLabel>cena:</StyledJobRequestLabel>
                        <StyledJobRequestText>{element.price} zł</StyledJobRequestText>
                      </StyledJobRequestPrice>
                      <StyledJobRequestDate>
                        <StyledJobRequestLabel>możliwy termin realizacji:</StyledJobRequestLabel>
                        <StyledJobRequestText>{format(new Date(element.startDate), 'dd.MM.yyyy')}</StyledJobRequestText>
                      </StyledJobRequestDate>
                      {element.clientStatus === 'Oczekuje na odpowiedź' && (
                        <StyledJobRequestButtons>
                          <Button
                            variant="contained"
                            className="styled-button"
                            onClick={() => {
                              acceptOffer(element._id);
                            }}
                          >
                            Przyjmij ofertę
                          </Button>
                          <Button
                            variant="contained"
                            className="styled-button"
                            onClick={() => {
                              rejectOffer(element._id);
                            }}
                          >
                            Odrzuć ofertę
                          </Button>
                        </StyledJobRequestButtons>
                      )}

                      {element.clientStatus === 'Oferta przyjęta' && <StyledJobRequestStatus>OFERTA ZAAKCEPTOWANA</StyledJobRequestStatus>}
                    </StyledJobRequest>
                  )
              )
            )}
          </StyledJobRequestsList>
        </StyledJobRequestsContainer>
      )}
    </StyledWrapper>
  );
};

export default UserDashboardJob;

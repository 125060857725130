import React from 'react';
import PageContent from 'data/pages/ForgottenPassword.json';
import ElementContainer from 'components/ElementContainer/ElementContainer';
import { StyledWrapper } from './PasswordReset.styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';

const PasswordReset = () => (
  <Container>
    <ElementContainer>
      <StyledWrapper>
        <TextField id="email-value" type="text" label="E-mail" className="styled-input" />
        <Button as={Link} to={PageContent.buttonUrl} variant="contained" className="styled-button">
          {PageContent.buttonTitle}
        </Button>
      </StyledWrapper>
    </ElementContainer>
  </Container>
);

export default PasswordReset;

import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 60px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 90px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 120px 0;
  }
`;

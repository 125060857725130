import React from 'react';
import ClearLocalStorage from 'components/ClearLocalStorage/ClearLocalStorage';
import { StyledWrapper, StyledNavigation } from './UserDashboardMenu.styles';
import { NavLink, useNavigate } from 'react-router-dom';
import GetUserType from 'components/GetUserType/GetUserType';

const UserDashboardMenu = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    ClearLocalStorage();

    navigate('/');
  };

  return (
    <StyledWrapper>
      <StyledNavigation>
        <ul>
          <li>
            <NavLink to="/panel-uzytkownika">{GetUserType() === 'CLIENT' ? 'lista zleceń' : 'lista ofert'}</NavLink>
          </li>
          <li>
            <NavLink to="/panel-uzytkownika/ustawienia">ustawienia</NavLink>
          </li>
          <li>
            <NavLink to="/panel-uzytkownika/pomoc">pomoc</NavLink>
          </li>
          <li>
            <button onClick={handleSignOut}>wyloguj</button>
          </li>
        </ul>
      </StyledNavigation>
    </StyledWrapper>
  );
};

export default UserDashboardMenu;

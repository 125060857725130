import styled from 'styled-components';

export const StyledWrapper = styled.div``;

export const StyledButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 60px 0;
`;

export const StyledJobsContainer = styled.section``;

export const StyledSectionHeader = styled.h2`
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 0.03rem;
  margin: 0;
  padding: 0 0 30px 0;
`;

export const StyledProgressBar = styled.div`
  padding: 0 0 60px 0;

  &.hidden {
    display: none;
  }

  .MuiLinearProgress-root {
    background-color: rgba(41, 103, 153, 0.25);

    .MuiLinearProgress-bar {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const StyledJobsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 0 30px 0;

  &.hidden {
    display: none;
  }
`;

export const StyledJob = styled.div`
  text-decoration: none;
  background-color: white;
  padding: 30px;
  margin: 0 30px 30px 0;
  border: 1px solid ${({ theme }) => theme.colors.white};
  transition: border 0.25s ease;
  width: 100%;
  max-width: 320px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &:last-of-type {
    margin: 0 0 30px 0;
  }
`;

export const StyledJobTitle = styled.div`
  font-size: 1rem;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  padding: 0 0 20px 0;
`;

export const StyledJobDate = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  padding: 0 0 10px 0;
`;

export const StyledJobAddress = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
`;

export const StyledJobStatus = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  padding: 20px 0 0 0;
`;

export const StyledEmptyJobsListInfo = styled.div`
  margin: 0 0 30px 0;
`;

import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 300;
    color: ${theme.colors.black};
  }

  *, *::after, *::before {
    box-sizing: inherit;
  }

  body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
  }

  button {
    font-family: 'Roboto', sans-serif;
  }

  a {
    font-family: 'Roboto', sans-serif;
    outline: none;
  }

  h1 {
    font-size: 2rem;
    font-weight: 100;
    letter-spacing: 0.03rem;
    text-transform: uppercase;
    text-align: center;
    margin: 0;

    @media (min-width: ${theme.breakpoints.sm}) {
      font-size: 2.5rem;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      font-size: 3rem;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
      font-size: 3.5rem;
    }
  }

  p {
    font-size: 1rem;
    margin: 0;

    @media (min-width: ${theme.breakpoints.sm}) {
      font-size: 1.125rem;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      font-size: 1.25rem;
    }
  }

  ul, ol {
    margin: 0;
    padding: 0;
  }

  a, button {
    &.styled-button {
      font-weight: 400;
      background-color: ${({ theme }) => theme.colors.primary};
      box-shadow: none;
      text-decoration: none;

      &:hover {
        @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
          background-color: ${({ theme }) => theme.colors.grey};
          box-shadow: none;
        }
      }
    }
  }

  a {
    &.styled-link {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.black};
      font-size: 1rem;

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 1.125rem;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 1.25rem;
      }

      &:hover {
        @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

  div {
    &.styled-input {
      width: calc(100vw - 32px);
      max-width: 320px;

      &.disabled {
        > div {
          &:hover {
            fieldset {
              @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
                border-color: rgba(0, 0, 0, 0.26) !important;
              }
            }
          }
        }
      }

      label {
        &[class*='focused'] {
          color: ${({ theme }) => theme.colors.primary};
        }

        &[class*='error'] {
          color: ${({ theme }) => theme.colors.error};
        }
      }

      > div {
        &[class*='focused'] {
          fieldset {
            border-color: ${({ theme }) => theme.colors.primary} !important;
            border-width: 1px !important;
          }
        }

        &[class*='error'] {
          fieldset {
            border-color: ${({ theme }) => theme.colors.error} !important;
            border-width: 1px !important;
          }
        }

        &:hover {
          fieldset {
            @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
              border-color: ${({ theme }) => theme.colors.primary} !important;
            }
          }
        }
      }

      > P {
        &[class*='error'] {
          color: ${({ theme }) => theme.colors.error};
        }
      }
    }
  }
`;

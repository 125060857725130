import React, { useState, useEffect } from 'react';
import GetUserType from 'components/GetUserType/GetUserType';
import {
  StyledWrapper,
  StyledButton,
  StyledJobsContainer,
  StyledSectionHeader,
  StyledProgressBar,
  StyledJobsList,
  StyledJob,
  StyledJobTitle,
  StyledJobDate,
  StyledJobAddress,
  StyledJobStatus,
  StyledEmptyJobsListInfo,
} from './UserDashboardJobsList.styles';
import Button from '@mui/material/Button';
import { Link, generatePath } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { format } from 'date-fns';

const hideProgressBars = () => {
  const progressBars = document.getElementsByClassName('progress-bar');

  for (let i = 0; i < progressBars.length; i++) {
    progressBars[i].classList.add('hidden');
  }
};

const hideProviderProgressBar = () => {
  const progressBar = document.getElementsByClassName('provider-progress-bar');

  for (let i = 0; i < progressBar.length; i++) {
    progressBar[i].classList.add('hidden');
  }
};

const showJobsLists = () => {
  const jobsLists = document.getElementsByClassName('jobs-list');

  for (let i = 0; i < jobsLists.length; i++) {
    jobsLists[i].classList.remove('hidden');
  }
};

const showProviderJobsList = () => {
  const jobsList = document.getElementsByClassName('provider-jobs-list');

  for (let i = 0; i < jobsList.length; i++) {
    jobsList[i].classList.remove('hidden');
  }
};

const UserDashboardJobsList = () => {
  const [newJobs, setNewJobs] = useState([]);
  const [currentJobs, setCurrentJobs] = useState([]);
  const [endedJobs, setEndedJobs] = useState([]);
  const [providerJobs, setProviderJobs] = useState([]);

  const getNewJobs = (jobs) => {
    let filteredJobs = [];

    jobs.forEach((job) => {
      if (job.status === 'WAITING_FOR_PROVIDER' || job.status === 'WAITING_FOR_APPROVAL') {
        filteredJobs.push(job);
      }
    });

    setNewJobs(filteredJobs);
  };

  const getCurrentJobs = (jobs) => {
    let filteredJobs = [];

    jobs.forEach((job) => {
      if (job.status === 'JOB_IN_PROGRESS' || job.status === 'WAITING_FOR_PAYMENT' || job.status === 'JOB_DONE_BY_PROVIDER') {
        filteredJobs.push(job);
      }
    });

    setCurrentJobs(filteredJobs);
  };

  const getEndedJobs = (jobs) => {
    let filteredJobs = [];

    jobs.forEach((job) => {
      if (job.status === 'JOB_DONE' || job.status === 'JOB_CANCELED') {
        filteredJobs.push(job);
      }
    });

    setEndedJobs(filteredJobs);
  };

  const getProviderJobs = (jobs) => {
    let filteredJobs = [];

    jobs.forEach((job) => {
      if (job.status === 'WAITING_FOR_PROVIDER') {
        filteredJobs.push(job);
      }
    });

    setProviderJobs(filteredJobs);
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_PROXY + 'api/job/' + (GetUserType() === 'CLIENT' ? 'client' : 'provider') + '_job_list', {
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('user_access_token'),
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((result) => {
        getNewJobs(result);
        getCurrentJobs(result);
        getEndedJobs(result);
      })
      .then(() => {
        hideProgressBars();
        showJobsLists();
      });

    fetch(process.env.REACT_APP_PROXY + 'api/job/', {
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('user_access_token'),
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((result) => {
        getProviderJobs(result);
      })
      .then(() => {
        hideProviderProgressBar();
        showProviderJobsList();
      });
  }, []);

  return (
    <StyledWrapper>
      {GetUserType() === 'CLIENT' && (
        <StyledButton>
          <Button as={Link} to="/panel-uzytkownika/dodaj-zlecenie" variant="contained" className="styled-button">
            Dodaj nowe zlecenie
          </Button>
        </StyledButton>
      )}

      {GetUserType() === 'PROVIDER' && (
        <StyledJobsContainer>
          <StyledSectionHeader>dostępne oferty</StyledSectionHeader>
          <StyledProgressBar className="provider-progress-bar">
            <LinearProgress />
          </StyledProgressBar>
          <StyledJobsList className="provider-jobs-list hidden">
            {providerJobs.length > 0 ? (
              providerJobs.map((job, index) => (
                <StyledJob
                  as={Link}
                  to={generatePath('/panel-uzytkownika/szczegoly-zlecenia?id=:id', {
                    id: job._id,
                  })}
                  key={index}
                >
                  <StyledJobTitle>{job.subcategory}</StyledJobTitle>
                  <StyledJobDate>
                    <div>Data zgłoszenia: </div>
                    <div>{format(new Date(job.startDate), 'dd.MM.yyyy')}</div>
                  </StyledJobDate>
                  <StyledJobAddress>
                    <div>Adres: </div>
                    <div>
                      {job.address.addressLine1}
                      {job.address.addressLine2 ? ' ' + job.address.addressLine2 : ''}
                      ,
                      <br />
                      {job.address.zipCode} {job.address.city}
                    </div>
                  </StyledJobAddress>
                  <StyledJobStatus>
                    <div>Status: </div>
                    <div>oczekuje na ofertę</div>
                  </StyledJobStatus>
                </StyledJob>
              ))
            ) : (
              <StyledEmptyJobsListInfo>brak ofert</StyledEmptyJobsListInfo>
            )}
          </StyledJobsList>
        </StyledJobsContainer>
      )}
      <StyledJobsContainer>
        <StyledSectionHeader>nowe {GetUserType() === 'CLIENT' ? 'zlecenia' : 'oferty'}</StyledSectionHeader>
        <StyledProgressBar className="progress-bar">
          <LinearProgress />
        </StyledProgressBar>
        <StyledJobsList className="jobs-list hidden">
          {newJobs.length > 0 ? (
            newJobs.map((job, index) => (
              <StyledJob
                as={Link}
                to={generatePath('/panel-uzytkownika/szczegoly-zlecenia?id=:id', {
                  id: job._id,
                })}
                key={index}
              >
                <StyledJobTitle>{job.subcategory}</StyledJobTitle>
                <StyledJobDate>
                  <div>Data zgłoszenia: </div>
                  <div>{format(new Date(job.startDate), 'dd.MM.yyyy')}</div>
                </StyledJobDate>
                <StyledJobAddress>
                  <div>Adres: </div>
                  <div>
                    {job.address.addressLine1}
                    {job.address.addressLine2 ? ' ' + job.address.addressLine2 : ''}
                    ,
                    <br />
                    {job.address.zipCode} {job.address.city}
                  </div>
                </StyledJobAddress>
                <StyledJobStatus>
                  <div>Status: </div>
                  <div>oczekuje na {job.status === 'WAITING_FOR_PROVIDER' ? 'ofertę' : 'akceptację oferty'}</div>
                </StyledJobStatus>
              </StyledJob>
            ))
          ) : (
            <StyledEmptyJobsListInfo>brak {GetUserType() === 'CLIENT' ? 'zleceń' : 'ofert'}</StyledEmptyJobsListInfo>
          )}
        </StyledJobsList>
      </StyledJobsContainer>

      <StyledJobsContainer>
        <StyledSectionHeader>{GetUserType() === 'CLIENT' ? 'zlecenia' : 'oferty'} w trakcie realizacji</StyledSectionHeader>
        <StyledProgressBar className="progress-bar">
          <LinearProgress />
        </StyledProgressBar>
        <StyledJobsList className="jobs-list hidden">
          {currentJobs.length > 0 ? (
            currentJobs.map((job, index) => (
              <StyledJob
                as={Link}
                to={generatePath('/panel-uzytkownika/szczegoly-zlecenia?id=:id', {
                  id: job._id,
                })}
                key={index}
              >
                <StyledJobTitle>{job.subcategory}</StyledJobTitle>
                <StyledJobDate>
                  <div>Data zgłoszenia: </div>
                  <div>{format(new Date(job.startDate), 'dd.MM.yyyy')}</div>
                </StyledJobDate>
                <StyledJobAddress>
                  <div>Adres: </div>
                  <div>
                    {job.address.addressLine1}
                    {job.address.addressLine2 ? ' ' + job.address.addressLine2 : ''}
                    ,
                    <br />
                    {job.address.zipCode} {job.address.city}
                  </div>
                </StyledJobAddress>
                <StyledJobStatus>
                  <div>Status: </div>
                  <div>
                    oczekuje na{' '}
                    {job.status === 'WAITING_FOR_PAYMENT'
                      ? 'opłacenie'
                      : job.status === 'JOB_IN_PROGRESS'
                      ? 'zakończenie zlecenia'
                      : 'potwierdzenie zakończenie zlecenia'}
                  </div>
                </StyledJobStatus>
              </StyledJob>
            ))
          ) : (
            <StyledEmptyJobsListInfo>brak {GetUserType() === 'CLIENT' ? 'zleceń' : 'ofert'}</StyledEmptyJobsListInfo>
          )}
        </StyledJobsList>
      </StyledJobsContainer>

      <StyledJobsContainer>
        <StyledSectionHeader>archiwalne {GetUserType() === 'CLIENT' ? 'zlecenia' : 'oferty'}</StyledSectionHeader>
        <StyledProgressBar className="progress-bar">
          <LinearProgress />
        </StyledProgressBar>
        <StyledJobsList className="jobs-list hidden">
          {endedJobs.length > 0 ? (
            endedJobs.map((job, index) => (
              <StyledJob
                as={Link}
                to={generatePath('/panel-uzytkownika/szczegoly-zlecenia?id=:id', {
                  id: job._id,
                })}
                key={index}
              >
                <StyledJobTitle>{job.subcategory}</StyledJobTitle>
                <StyledJobDate>
                  <div>Data zgłoszenia: </div>
                  <div>{format(new Date(job.startDate), 'dd.MM.yyyy')}</div>
                </StyledJobDate>
                <StyledJobAddress>
                  <div>Adres: </div>
                  <div>
                    {job.address.addressLine1}
                    {job.address.addressLine2 ? ' ' + job.address.addressLine2 : ''}
                    ,
                    <br />
                    {job.address.zipCode} {job.address.city}
                  </div>
                </StyledJobAddress>
                <StyledJobStatus>
                  <div>Status: </div>
                  <div>{job.status === 'JOB_DONE' ? 'zakończone' : 'anulowane'}</div>
                </StyledJobStatus>
              </StyledJob>
            ))
          ) : (
            <StyledEmptyJobsListInfo>brak {GetUserType() === 'CLIENT' ? 'zleceń' : 'ofert'}</StyledEmptyJobsListInfo>
          )}
        </StyledJobsList>
      </StyledJobsContainer>
    </StyledWrapper>
  );
};

export default UserDashboardJobsList;

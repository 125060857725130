import styled from 'styled-components';

export const StyledWrapper = styled.div``;

export const StyledContainer = styled.div`
  padding: 80px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 120px 0;
  }
`;

export const LinkTilesItem = styled.a`
  display: block;
  width: 100%;
  height: 300px;
  text-decoration: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 340px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 380px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    position: relative;
  }

  &:not(:last-of-type) {
    margin: 0 0 30px 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin: 0;
    }
  }

  &::before {
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 50%;
      background-color: rgba(255, 255, 255, 0);
      transition: background-color 0.2s linear;
    }
  }

  &:hover {
    &::before {
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
`;

export const LinkTileImage = styled.div`
  width: 100%;
  height: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const LinkTileText = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: black;
  padding: 0 20px;
  font-size: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.125rem;
  }
`;

import styled from 'styled-components';

export const StyledIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: 2/3;
    justify-content: flex-end;
  }

  a {
    width: 30px !important;
    height: 30px !important;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      width: 32px !important;
      height: 32px !important;
    }

    &:not(:last-of-type) {
      margin: 0 15px 0 0;

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        margin: 0 16px 0 0;
      }
    }

    .social-svg-mask {
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        transition: fill 0.2s linear;
      }
    }

    &:hover {
      .social-svg-mask {
        @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
          fill: ${({ theme }) => theme.colors.primary} !important;
        }
      }
    }
  }
`;

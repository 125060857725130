import React from 'react';
import { Container } from '@mui/material';
import ElementContainer from 'components/ElementContainer/ElementContainer';
import PropTypes from 'prop-types';

const Title = ({ title }) => (
  <Container>
    <ElementContainer>
      <h1>{title}</h1>
    </ElementContainer>
  </Container>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Title;

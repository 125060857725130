import React from 'react';
import GroupContainer from 'components/GroupContainer/GroupContainer';
import Title from 'components/Title/Title';
import LoginRegister from 'components/LoginRegister/LoginRegister';

const Login = () => (
  <GroupContainer>
    <Title title="Logowanie i rejestracja" />
    <LoginRegister />
  </GroupContainer>
);

export default Login;

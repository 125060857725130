import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GetUserAccessToken from 'components/GetUserAccessToken/GetUserAccessToken';
import {
  StyledWrapper,
  StyledFieldsContainer,
  StyledField,
  StyledProgressIndicator,
  StyledValidationErrors,
  StyledButtonContainer,
} from './UserDashboardAddJob.styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';

const UserDashboardAddJob = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  let categoriesCounter = 0;

  const getSubcategoryCategoryID = (searchedSubcategoryName) => {
    let categoryID = '';

    categories.map((category, index) => {
      category.subcategoryList.map((subcategory, index) => {
        if (subcategory.subcategoryName === searchedSubcategoryName) {
          categoryID = category.categoryId;
        }
      });
    });

    return categoryID;
  };

  const checkEmptyFields = (fields) => {
    let isEmpty = false;

    fields.forEach((field) => {
      if (!field) {
        isEmpty = true;
      }
    });

    return isEmpty;
  };

  const handleAddJob = () => {
    const subcategoryValue = document.getElementById('category-select').innerText;
    const descriptionValue = document.getElementById('description').value;
    const addressValue = document.getElementById('address').value;
    const zipCodeValue = document.getElementById('zip-code').value;
    const cityValue = document.getElementById('city').value;
    const preferredDateValue = document.getElementById('preferred-date').value;
    const currentDate = new Date().toISOString().split('T')[0];
    const formFields = [descriptionValue, addressValue, zipCodeValue, cityValue, preferredDateValue];
    const UserAccessToken = GetUserAccessToken();
    const progressIndicator = document.getElementById('progress-indicator');
    const emptyFieldsError = document.getElementById('empty-fields-error');

    emptyFieldsError.classList.add('hidden');
    progressIndicator.classList.remove('hidden');

    setTimeout(() => {
      progressIndicator.classList.add('hidden');

      if (checkEmptyFields(formFields) || subcategoryValue.length < 2) {
        emptyFieldsError.classList.remove('hidden');
      } else {
        const newJobData = {
          _id: null,
          clientId: null,
          categoryId: getSubcategoryCategoryID(),
          subcategory: subcategoryValue,
          address: {
            addressLine1: addressValue,
            addressLine2: '',
            city: cityValue,
            zipCode: zipCodeValue,
          },
          prefferDate: preferredDateValue,
          startDate: currentDate,
          endDate: '',
          details: descriptionValue,
          price: 0,
          clientRating: null,
          companyRating: null,
          providerId: null,
          paymentStatus: null,
          photoList: [],
          orderId: null,
          status: null,
          jobRequestList: [],
          confirmed: false,
        };

        fetch(process.env.REACT_APP_PROXY + 'api/job/add', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + UserAccessToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newJobData),
        });

        navigate('/panel-uzytkownika');
      }
    }, 750);
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_PROXY + 'api/category/')
      .then((response) => response.json())
      .then((result) => {
        setCategories(result);
      });
  }, []);

  return (
    <StyledWrapper>
      <StyledFieldsContainer>
        <StyledField>
          <FormControl className="styled-input">
            <InputLabel htmlFor="category-select">Kategoria</InputLabel>
            <Select defaultValue="" id="category-select" label="Kategoria">
              {categories.map((category, index) => [
                <ListSubheader key={index}>{category.name}</ListSubheader>,
                category.subcategoryList.map((subcategory, index) => (
                  <MenuItem key={index} value={categoriesCounter++}>
                    {subcategory.subcategoryName}
                  </MenuItem>
                )),
              ])}
            </Select>
          </FormControl>
        </StyledField>
        <StyledField>
          <TextField id="description" type="text" label="Opis zlecenia" multiline rows={4} className="styled-input" />
        </StyledField>
      </StyledFieldsContainer>
      <StyledFieldsContainer>
        <StyledField>
          <TextField id="address" type="text" label="Adres" className="styled-input" />
        </StyledField>
        <StyledField>
          <TextField id="zip-code" type="text" label="Kod pocztowy" className="styled-input" />
        </StyledField>
        <StyledField>
          <TextField id="city" type="text" label="Miasto" className="styled-input" />
        </StyledField>
      </StyledFieldsContainer>
      <StyledFieldsContainer className="noBottomPadding">
        <StyledField>
          <TextField id="preferred-date" type="text" label="Preferowana data wykonania" className="styled-input" />
        </StyledField>
      </StyledFieldsContainer>
      <StyledProgressIndicator id="progress-indicator" className="hidden">
        <CircularProgress />
      </StyledProgressIndicator>
      <StyledValidationErrors>
        <div id="empty-fields-error" className="hidden">
          Wszystkie pola muszą być uzupełnione.
          <br />
          Upewnij się, że wypełnione jest każde z pól i spróbuj dodać zlecenie ponownie.
        </div>
      </StyledValidationErrors>
      <StyledButtonContainer>
        <Button variant="contained" className="styled-button" onClick={handleAddJob}>
          Dodaj zlecenie
        </Button>
      </StyledButtonContainer>
    </StyledWrapper>
  );
};

export default UserDashboardAddJob;

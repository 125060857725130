import React from 'react';
import { StyledContainer, StyledLogo, StyledTitle } from './UserDashboardTopBar.styles';
import LogoSmall from 'assets/images/logo-small.svg';
import LogoBig from 'assets/images/logo-big.svg';
import PropTypes from 'prop-types';

const UserDashboardTopBar = ({ pageTitle }) => {
  return (
    <StyledContainer>
      <StyledLogo>
        <a href="/">
          <img id="logo-small" src={LogoSmall} alt="logo strony" />
          <img id="logo-big" src={LogoBig} alt="logo strony" />
        </a>
      </StyledLogo>
      <StyledTitle>
        panel użytkownika
        {pageTitle && (
          <>
            <span className="text-separator">•</span>
            {pageTitle}
          </>
        )}
      </StyledTitle>
    </StyledContainer>
  );
};

UserDashboardTopBar.propTypes = {
  pageTitle: PropTypes.string,
};

export default UserDashboardTopBar;

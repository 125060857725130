import React from 'react';
import GroupContainer from 'components/GroupContainer/GroupContainer';
import Title from 'components/Title/Title';
import Register from 'components/Register/Register';

const CreateAccount = () => (
  <GroupContainer>
    <Title title="Tworzenie konta" />
    <Register />
  </GroupContainer>
);

export default CreateAccount;

import React from 'react';
import { Container } from '@mui/material';
import ElementContainer from 'components/ElementContainer/ElementContainer';
import { StyledContactDetails, StyledMap, StyledWrapper } from './ContactInfo.styles';

const ContactInfo = () => (
  <Container>
    <ElementContainer>
      <StyledWrapper>
        <StyledContactDetails>
          <p>Serviceway sp. z o.o.</p>
          <a href="https://goo.gl/maps/gTnf8qco81grCHvX6" target="_blank" rel="noreferrer">
            ul. Kutrzeby 10
            <br />
            61-719 Poznań
          </a>

          <a href="tel:+48612711000">61 271 10 00</a>
          <a href="mailto:kontakt@serviceway.pl">kontakt@serviceway.pl</a>
        </StyledContactDetails>
        <StyledMap>
          <iframe
            title="map"
            referrerPolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2433.4959360188195!2d16.92933611530102!3d52.415811151698435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!3m2!1spl!2spl!4v1655200409511!5m2!1spl!2spl"
          ></iframe>
        </StyledMap>
      </StyledWrapper>
    </ElementContainer>
  </Container>
);

export default ContactInfo;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, CircularProgress } from '@mui/material';
import ElementContainer from 'components/ElementContainer/ElementContainer';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import {
  StyledWrapper,
  StyledUserTypeSelection,
  StyledUserData,
  StyledRulesConsent,
  StyledProgressIndicator,
  StyledValidationErrors,
  StyledRegistrationButton,
  StyledColumn,
  StyledFieldsGroup,
  StyledInput,
} from './Register.styles';

const Register = () => {
  const navigate = useNavigate();

  // region User type selection
  const [userType, setUserType] = useState('CLIENT');
  const handleUserTypeSelection = (event) => {
    setUserType(event.target.value);
  };
  // endregion

  // region Service categories
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    fetch(process.env.REACT_APP_PROXY + 'api/category/')
      .then((response) => response.json())
      .then((result) => {
        setCategories(result);
      });
  }, []);

  const [categoryName, setCategoryName] = useState([]);
  const handleChange = (event: SelectChangeEvent<typeof categoryName>) => {
    const {
      target: { value },
    } = event;
    setCategoryName(value);
  };
  // endregion

  // region Registration button
  const checkEmptyFields = (fields) => {
    let isEmpty = false;

    fields.forEach((field) => {
      if (!field) {
        isEmpty = true;
      }
    });

    return isEmpty;
  };

  const validateEmailAddress = (email) => {
    return email
      .toLowerCase()
      .match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
  };

  const validatePasswords = (password, repeatedPassword) => {
    return password.match(repeatedPassword);
  };

  const handleRegister = () => {
    const progressIndicator = document.getElementById('progress-indicator');
    const firstNameValue = document.getElementById('first-name').value;
    const lastNameValue = document.getElementById('last-name').value;
    const phoneNumberValue = document.getElementById('phone-number').value;
    const emailAddressValue = document.getElementById('e-mail-address').value;
    const passwordValue = document.getElementById('password').value;
    const repeatedPasswordValue = document.getElementById('repeated-password').value;
    const rulesAcceptance = document.getElementById('rules-acceptance').parentElement;

    const fields = [firstNameValue, lastNameValue, phoneNumberValue, emailAddressValue, passwordValue, repeatedPasswordValue];

    const registrationData = {
      firstName: firstNameValue,
      lastName: lastNameValue,
      phone: phoneNumberValue,
      username: emailAddressValue,
      password: passwordValue,
    };

    if (userType === 'PROVIDER') {
      const companyNameValue = document.getElementById('company-name').value;
      const taxNumberValue = document.getElementById('tax-number').value;
      const addressValue = document.getElementById('address').value;
      const zipCodeValue = document.getElementById('zip-code').value;
      const cityValue = document.getElementById('city').value;
      const selectedCategories = document.getElementById('selected-categories');

      fields.push(companyNameValue, taxNumberValue, addressValue, zipCodeValue, cityValue, selectedCategories);

      registrationData.companyName = companyNameValue;
      registrationData.nip = taxNumberValue;
      registrationData.address = {
        addressLine1: addressValue,
        addressLine2: null,
        zipCode: zipCodeValue,
        city: cityValue,
      };
      registrationData.categoryList = null;
    }

    const emptyFieldsError = document.getElementById('empty-fields-error');
    const incorrectEmailAddressError = document.getElementById('incorrect-email-address-error');
    const passwordsNotIdenticalError = document.getElementById('passwords-not-identical-error');
    const rulesNotAcceptedError = document.getElementById('rules-not-accepted-error');

    emptyFieldsError.classList.add('hidden');
    incorrectEmailAddressError.classList.add('hidden');
    passwordsNotIdenticalError.classList.add('hidden');
    rulesNotAcceptedError.classList.add('hidden');
    progressIndicator.classList.remove('hidden');

    setTimeout(() => {
      progressIndicator.classList.add('hidden');

      if (checkEmptyFields(fields)) {
        emptyFieldsError.classList.remove('hidden');
      } else if (!validateEmailAddress(emailAddressValue)) {
        incorrectEmailAddressError.classList.remove('hidden');
      } else if (!validatePasswords(passwordValue, repeatedPasswordValue)) {
        passwordsNotIdenticalError.classList.remove('hidden');
      } else if (!rulesAcceptance.classList.contains('Mui-checked')) {
        rulesNotAcceptedError.classList.remove('hidden');
      } else {
        registrationData.privacyPolicy = true;
        registrationData.terms = true;
        registrationData.toBeDeleted = false;
        registrationData.rating = null;
        registrationData.userRole = userType;
        registrationData.enabled = true;

        fetch(process.env.REACT_APP_PROXY + 'api/user/add', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(registrationData),
        });

        navigate('/potwierdzenie-rejestracji');
      }
    }, 750);
  };
  // endregion

  return (
    <Container>
      <ElementContainer>
        <StyledWrapper>
          {/* region User type selection */}
          <StyledUserTypeSelection>
            <FormControl>
              <FormLabel>Zarejestruj się jako:</FormLabel>
              <RadioGroup value={userType} onChange={handleUserTypeSelection}>
                <FormControlLabel value="CLIENT" label="Zleceniodawca" control={<Radio />} />
                <FormControlLabel value="PROVIDER" label="Zleceniobiorca" control={<Radio />} />
              </RadioGroup>
            </FormControl>
          </StyledUserTypeSelection>
          {/* endregion */}
          {/* region User data */}
          <StyledUserData>
            <StyledColumn>
              {/* region Basic user data */}
              <StyledFieldsGroup>
                <StyledInput>
                  <TextField id="first-name" type="text" label="Imię" className="styled-input" />
                </StyledInput>
                <StyledInput>
                  <TextField id="last-name" type="text" label="Nazwisko" className="styled-input" />
                </StyledInput>
                <StyledInput>
                  <TextField id="phone-number" type="text" label="Numer telefonu" className="styled-input" />
                </StyledInput>
              </StyledFieldsGroup>
              {/* endregion */}
              {/* region Company data */}
              {userType === 'PROVIDER' && (
                <StyledFieldsGroup>
                  <StyledInput>
                    <TextField id="company-name" type="text" label="Nazwa firmy" className="styled-input" />
                  </StyledInput>
                  <StyledInput>
                    <TextField id="tax-number" type="text" label="NIP" className="styled-input" />
                  </StyledInput>
                  <StyledInput>
                    <TextField id="address" type="text" label="Adres" className="styled-input" />
                  </StyledInput>
                  <StyledInput>
                    <TextField id="zip-code" type="text" label="Kod pocztowy" className="styled-input" />
                  </StyledInput>
                  <StyledInput>
                    <TextField id="city" type="text" label="Miasto" className="styled-input" />
                  </StyledInput>
                </StyledFieldsGroup>
              )}
              {/* endregion */}
            </StyledColumn>
            <StyledColumn>
              {/* region Service categories */}
              {userType === 'PROVIDER' && (
                <StyledFieldsGroup>
                  <StyledInput>
                    <FormControl>
                      <InputLabel>Kategorie świadczonych usług</InputLabel>
                      <Select
                        multiple
                        value={categoryName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Kategorie świadczonych usług" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }} id="selected-categories">
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        className="styled-input"
                      >
                        {categories.map((category) => (
                          <MenuItem key={category.name} value={category.name}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </StyledInput>
                </StyledFieldsGroup>
              )}
              {/* endregion */}
              {/* region User account data */}
              <StyledFieldsGroup>
                <StyledInput>
                  <TextField id="e-mail-address" type="text" label="Adres e-mail" className="styled-input" />
                </StyledInput>
                <StyledInput>
                  <TextField id="password" type="password" label="Hasło" className="styled-input" />
                </StyledInput>
                <StyledInput>
                  <TextField id="repeated-password" type="password" label="Potwórz hasło" className="styled-input" />
                </StyledInput>
              </StyledFieldsGroup>
              {/* endregion */}
            </StyledColumn>
          </StyledUserData>
          {/* endregion */}
          {/* region Rules consent  */}
          <StyledRulesConsent>
            <FormGroup>
              <FormControlLabel control={<Checkbox id="rules-acceptance" />} label="Akcpetacja regulaminu" />
            </FormGroup>
          </StyledRulesConsent>
          {/* endregion */}
          {/* region Progress indicator  */}
          <StyledProgressIndicator id="progress-indicator" className="hidden">
            <CircularProgress />
          </StyledProgressIndicator>
          {/* endregion */}
          {/* region Validation errors  */}
          <StyledValidationErrors>
            <div id="empty-fields-error" className="hidden">
              Wszystkie pola muszą być uzupełnione.
              <br />
              Upewnij się, że wypełnione jest każde z pól i spróbuj zarejestrować się ponownie.
            </div>
            <div id="incorrect-email-address-error" className="hidden">
              Wprowadzony adres e-mail jest niepoprawny.
              <br />
              Upewnij się, że podany został właściwy adres e-mail i spróbuj zarejestrować się ponownie.
            </div>
            <div id="passwords-not-identical-error" className="hidden">
              Wprowadzone hasła nie są identyczne.
              <br />
              Sprawdź identyczność podanych haseł i spróbuj zarejestrować się ponownie.
            </div>
            <div id="rules-not-accepted-error" className="hidden">
              Aby ukończyć rejestrację należy zaakcpetować regulamin serwisu.
              <br />
              Zaakceptuj go i spróbuj zarejestrować się ponownie.
            </div>
          </StyledValidationErrors>
          {/* endregion */}
          {/* region Registration button  */}
          <StyledRegistrationButton>
            <Button variant="contained" className="styled-button" onClick={handleRegister}>
              Zarejestruj się
            </Button>
          </StyledRegistrationButton>
          {/* endregion */}
        </StyledWrapper>
      </ElementContainer>
    </Container>
  );
};

export default Register;

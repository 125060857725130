import styled from 'styled-components';

export const StyledWrapper = styled.div``;

export const StyledContainer = styled.div`
  padding: 80px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    padding: 120px 0;
  }

  p {
    text-align: center;
  }

  &.textOnLeft {
    p {
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-column: 1/4;
        text-align: left;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        grid-column: 1/3;
      }
    }
  }

  &.textOnRight {
    p {
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-column: 2/5;
        text-align: right;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        grid-column: 2/4;
      }
    }
  }
`;

import ClearLocalStorage from 'components/ClearLocalStorage/ClearLocalStorage';

const IsUserLoggedIn = () => {
  const token = localStorage.getItem('user_access_token');
  const currentDate = new Date();
  const tokensExpirationDate = Date.parse(localStorage.getItem('user_tokens_expiration_date'));

  if (token && tokensExpirationDate - currentDate > 0) {
    return true;
  } else {
    ClearLocalStorage();

    return false;
  }
};

export default IsUserLoggedIn;

import React from 'react';
import { Container } from '@mui/material';
import { StyledWrapper, StyledContainer, Title, Text, Button } from './MainBanner.styles';
import backgroundImage from 'assets/images/mainBannerBackground-2.jpg';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const scrollDown = () => {
  const viewHeight = window.innerHeight;
  const offset = 80;
  const scrollPosition = viewHeight - offset;

  window.scroll({
    top: scrollPosition,
    behavior: 'smooth',
  });
};

const MainBanner = () => (
  <StyledWrapper style={{ backgroundImage: 'url(' + backgroundImage + ')' }}>
    <Container>
      <StyledContainer>
        <Title>jesteśmy serviceway</Title>
        <Text>Oferujesz usługi? Szukasz kogoś, kto je dla Ciebie wykona? Dobrze trafiłeś!</Text>
        <Button onClick={scrollDown}>
          <KeyboardArrowDownOutlinedIcon />
        </Button>
      </StyledContainer>
    </Container>
  </StyledWrapper>
);

export default MainBanner;

import styled from 'styled-components';

export const StyledWrapper = styled.div`
  max-width: 560px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 60px;
  }
`;

export const StyledText = styled.div`
  padding: 0 0 60px 0;
`;

export const StyledField = styled.div`
  padding: 0 0 30px 0;

  .styled-input {
    max-width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledProgressIndicator = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 15px 0;

  &.hidden {
    display: none;
  }

  > span {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledError = styled.div`
  text-align: center;
  padding: 0 0 15px 0;
  color: ${({ theme }) => theme.colors.error};

  &.hidden {
    display: none;
  }
`;

export const StyledButton = styled.div`
  padding: 15px 0 0 0;
  text-align: center;
`;

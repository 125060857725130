import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 30px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 45px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 60px 0;
  }
`;

import styled from 'styled-components';

export const StyledWrapper = styled.div``;

export const StyledFormContainer = styled.div`
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 560px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 60px;
  }
`;

export const StyledField = styled.div`
  padding: 0 0 30px 0;

  &.date-picker {
    > div {
      width: 100%;

      &:hover {
        fieldset {
          border-width: 1px;

          @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
            border-color: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }
  }

  .styled-input {
    max-width: none;
    width: 100%;
  }
`;

export const StyledProgressIndicator = styled.div`
  text-align: center;

  &.hidden {
    display: none;
  }
`;

export const StyledValidationErrors = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.error};

  .hidden {
    display: none;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0 0 0;
`;

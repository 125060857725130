export const theme = {
  colors: {
    primary: '#296799',
    primaryVariant: '#cad9e6',
    backgroundPrimary: '#f7f9fa',
    backgroundSecondary: '#eaf0f5',
    disabled: '#d8dde1',
    error: '#d23927',
    dark: '#15344d',
    grey: '#90a5b5',
    lightGrey: '#eeeeee',
    darkGrey: '#616161',
    white: '#ffffff',
    black: '#000000',
  },
  breakpoints: {
    xs: '0px',
    sm: '600px',
    md: '900px',
    lg: '1200px',
    xl: '1536px',
  },
};

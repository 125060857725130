import styled from 'styled-components';

export const StyledWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const StyledJobDetailsContainer = styled.div`
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 560px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 60px;
  }
`;

export const StyledJobDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const StyledJobDetailTitle = styled.h2`
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 0.03rem;
  margin: 0;
  padding: 0 0 45px 0;
`;

export const StyledJobDetailLabel = styled.div`
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.03rem;
  white-space: nowrap;
  padding: 0 0 10px 0;
`;

export const StyledJobDetailText = styled.div`
  font-size: 1rem;
  padding: 0 0 30px 0;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0 0 0;
`;

export const StyledJobRequestsContainer = styled.div`
  margin: 60px 0 0 0;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 560px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 560px;
    padding: 60px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 0;
  }
`;

export const StyledJobRequestsListHeader = styled.h2`
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 0.03rem;
  margin: 0;
  padding: 0 0 45px 0;
  text-align: center;
`;

export const StyledJobConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledRate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledRateText = styled.div`
  font-size: 1rem;
  padding: 0 0 15px 0;
`;

export const StyledRateElement = styled.div`
  padding: 0 0 10px 0;

  label {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledJobRequestsList = styled.div``;

export const StyledJobRequest = styled.div`
  padding: 45px 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
`;

export const StyledJobRequestMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledJobRequestPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledJobRequestDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledJobRequestButtons = styled.div`
  padding: 15px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

export const StyledJobRequestLabel = styled.div`
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.03rem;
  white-space: nowrap;
  padding: 0 0 10px 0;
`;

export const StyledJobRequestText = styled.div`
  font-size: 1rem;
  padding: 0 0 30px 0;
`;

export const StyledJobRequestStatus = styled.div`
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.03rem;
  white-space: nowrap;
  text-align: center;
  padding: 30px 0 0 0;
`;

import React from 'react';
import PageContent from 'data/pages/RegisterConfirmation.json';
import GroupContainer from 'components/GroupContainer/GroupContainer';
import Title from 'components/Title/Title';
import Description from 'components/Description/Description';
import RedirectButton from 'components/RedirectButton/RedirectButton';

const RegisterConfirmation = () => (
  <GroupContainer>
    <Title title={PageContent.title} />
    <Description description={PageContent.description} />
    <RedirectButton buttonUrl={PageContent.buttonUrl} buttonTitle={PageContent.buttonTitle} />
  </GroupContainer>
);

export default RegisterConfirmation;

import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 16px;
  width: calc(100vw - 32px);
  max-width: 400px;
  background-color: ${({ theme }) => theme.colors.darkGrey};
  padding: 20px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.25s, visibility 0.25s;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    bottom: 24px;
    padding: 25px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 30px;
    max-width: 500px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    left: 24px;
    transform: none;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledHeader = styled.span`
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 0 10px 0;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0 0 15px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 0 20px 0;
  }
`;

export const StyledText = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 0 20px 0;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0 0 25px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 0 30px 0;
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.darkGrey};
    box-shadow: none;
    font-size: 0.875rem;

    &:hover {
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
        box-shadow: none;
      }
    }
  }
`;

import styled from 'styled-components';

export const StyledWrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

export const StyledContainer = styled.div`
  padding: 20px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: 75% 25%;
  }
`;

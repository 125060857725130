import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -15px 20px 0px rgba(0, 0, 0, 1);
  z-index: 2;
`;

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const MenuButton = styled.div`
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0 -6px;

  svg {
    width: 48px;
    height: 48px;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      cursor: pointer;
      transition: color 0.25s ease;
    }

    &:hover {
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const MenuContent = styled.nav`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;

  &.show {
    display: flex;
  }

  svg {
    width: 40px;
    height: 40px;
    margin: -60px 0 60px 0;
    color: ${({ theme }) => theme.colors.white};

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      cursor: pointer;
      transition: color 0.25s ease;
    }

    &:hover {
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  div {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.white};

    &:not(:last-of-type) {
      padding: 0 0 60px 0;
    }

    ul {
      padding: 10px 0 0 0;
      list-style: none;

      li {
        &:not(:last-of-type) {
          padding: 0 0 10px 0;
        }
      }
    }

    a {
      color: ${({ theme }) => theme.colors.white};
      text-decoration: none;

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        transition: color 0.25s ease;
      }

      &:hover {
        @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`;

export const LogoContainer = styled(NavLink)`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 60%;
  }

  #logo-small {
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      display: none;
    }
  }

  #logo-big {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      display: block;
    }
  }
`;

export const UserButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiSvgIcon-root {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }

  span {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: inline;
    }
  }
`;

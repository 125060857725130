import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { ThemeProvider } from 'styled-components';
import { theme } from 'assets/styles/theme';
import { GlobalStyle } from 'assets/styles/GlobalStyle';
import WebsiteTemplate from 'templates/Website/WebsiteTemplate';
import Home from './Website/Home';
import Login from './Website/Login';
import CreateAccount from './Website/CreateAccount';
import RegisterConfirmation from './Website/RegisterConfirmation';
import ForgottenPassword from './Website/ForgottenPassword';
import Contact from './Website/Contact';
import Faq from './Website/Faq';
import Rules from './Website/Rules';
import PrivacyPolicy from './Website/PrivacyPolicy';
import Rodo from './Website/Rodo';
import Error from './Website/Error';
import UserDashboardTemplate from 'templates/UserDashboardTemplate/UserDashboardTemplate';
import UserDashboardHome from 'views/UserDashboard/UserDashboardHome';
import UserDashboardAddNewJob from 'views/UserDashboard/UserDashboardAddNewJob';
import UserDashboardJobDetails from 'views/UserDashboard/UserDashboardJobDetails';
import UserDashboardRequestToJob from 'views/UserDashboard/UserDashboardRequestToJob';
import UserDashboardSettings from 'views/UserDashboard/UserDashboardSettings';
import UserDashboardHelp from 'views/UserDashboard/UserDashboardHelp';
import PageNotFound from 'components/PageNotFound/PageNotFound';
import CookieInfo from 'components/CookieInfo/CookieInfo';

const Root = () => (
  <Router>
    <ScrollToTop />
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes>
        {/*region Website routes*/}
        <Route
          path="/"
          element={
            <WebsiteTemplate>
              <Home />
            </WebsiteTemplate>
          }
        />
        <Route
          path="/logowanie"
          element={
            <WebsiteTemplate>
              <Login />
            </WebsiteTemplate>
          }
        />
        <Route
          path="/rejestracja"
          element={
            <WebsiteTemplate>
              <CreateAccount />
            </WebsiteTemplate>
          }
        />
        <Route
          path="/potwierdzenie-rejestracji"
          element={
            <WebsiteTemplate>
              <RegisterConfirmation />
            </WebsiteTemplate>
          }
        />
        <Route
          path="/resetowanie-hasla"
          element={
            <WebsiteTemplate>
              <ForgottenPassword />
            </WebsiteTemplate>
          }
        />
        <Route
          path="/kontakt"
          element={
            <WebsiteTemplate>
              <Contact />
            </WebsiteTemplate>
          }
        />
        <Route
          path="/faq"
          element={
            <WebsiteTemplate>
              <Faq />
            </WebsiteTemplate>
          }
        />
        <Route
          path="/regulamin"
          element={
            <WebsiteTemplate>
              <Rules />
            </WebsiteTemplate>
          }
        />
        <Route
          path="/polityka-prywatnosci"
          element={
            <WebsiteTemplate>
              <PrivacyPolicy />
            </WebsiteTemplate>
          }
        />
        <Route
          path="/rodo"
          element={
            <WebsiteTemplate>
              <Rodo />
            </WebsiteTemplate>
          }
        />
        <Route
          path="/blad"
          element={
            <WebsiteTemplate>
              <Error />
            </WebsiteTemplate>
          }
        />
        {/*endregion*/}
        {/*region User dashboard routes*/}
        <Route
          path="/panel-uzytkownika"
          element={
            <UserDashboardTemplate pageTitle="strona główna">
              <UserDashboardHome />
            </UserDashboardTemplate>
          }
        />
        <Route
          path="/panel-uzytkownika/dodaj-zlecenie"
          element={
            <UserDashboardTemplate pageTitle="dodaj nowe zlecenie">
              <UserDashboardAddNewJob />
            </UserDashboardTemplate>
          }
        />
        <Route
          path="/panel-uzytkownika/szczegoly-zlecenia"
          element={
            <UserDashboardTemplate pageTitle="szczegóły zlecenia">
              <UserDashboardJobDetails />
            </UserDashboardTemplate>
          }
        />
        <Route
          path="/panel-uzytkownika/odpowiedz-na-oferte"
          element={
            <UserDashboardTemplate pageTitle="Odpowiedź na ofertę">
              <UserDashboardRequestToJob />
            </UserDashboardTemplate>
          }
        />
        <Route
          path="/panel-uzytkownika/ustawienia"
          element={
            <UserDashboardTemplate pageTitle="ustawienia">
              <UserDashboardSettings />
            </UserDashboardTemplate>
          }
        />
        <Route
          path="/panel-uzytkownika/pomoc"
          element={
            <UserDashboardTemplate pageTitle="pomoc">
              <UserDashboardHelp />
            </UserDashboardTemplate>
          }
        />
        {/*endregion*/}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <CookieInfo />
    </ThemeProvider>
  </Router>
);

export default Root;

import React from 'react';
import PageContent from 'data/pages/ForgottenPassword.json';
import GroupContainer from 'components/GroupContainer/GroupContainer';
import Title from 'components/Title/Title';
import Description from 'components/Description/Description';
import PasswordReset from 'components/PasswordReset/PasswordReset';

const ForgottenPassword = () => (
  <GroupContainer>
    <Title title={PageContent.title} />
    <Description description={PageContent.description} />
    <PasswordReset />
  </GroupContainer>
);

export default ForgottenPassword;

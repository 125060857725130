import React from 'react';
import { Container } from '@mui/material';
import ElementContainer from 'components/ElementContainer/ElementContainer';
import { StyledWrapper } from './RedirectButton.styles';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const RedirectButton = ({ buttonUrl, buttonTitle }) => (
  <Container>
    <ElementContainer>
      <StyledWrapper className="centered">
        <Button as={Link} to={buttonUrl} variant="contained">
          {buttonTitle}
        </Button>
      </StyledWrapper>
    </ElementContainer>
  </Container>
);

RedirectButton.propTypes = {
  buttonUrl: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
};

export default RedirectButton;
